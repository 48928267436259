import styled from 'styled-components'

export const AvatarLegend = styled.p`
    font-family: "Staatliches";
    word-spacing: 0.3em;
    font-style: italic;
    margin-top: 2rem;
    line-height: 1.3rem;
    word-spacing: 0.3rem;
`
export const AvatarLegendWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const AvatarWrapper = styled.section`

    width: 400px;
    position: relative;
    left: 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 1000px) {
        display: none;
    }

    @media(max-width: 1260px) {
        position: initial; 
    }
`