import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout/"
import Seo from "../components/seo"
import Avatar from "../components/Avatar"
import AvatarLegend from "../components/AvatarLegend"
import { AvatarWrapper } from "../components/AvatarLegend/styled"

import Button from "../components/Button"
import PageDisclaimer from "../components/PageDisclaimer"
import Typography from "../components/Typography"
import LinksWrapper from "../components/LinksWrapper"
import { LinkAbout } from "../components/LinksWrapper/styled"
import Wrapper from "../components/Wrapper"
import SocialLinks from "../components/SocialLinks"

const IndexPage = () => {
  const 
    { site : 
      { siteMetadata: { description }}
      } = useStaticQuery(graphql`
            query MySiteMetadata {
              site {
                siteMetadata {
                  description
                }
              }
            }
      `)

    return (
      <Layout>
        <Wrapper>
          <Seo title="Home" />
          <section style={{ width: "100%", maxWidth: "650px"}}>
            <PageDisclaimer disclaimerTopText="Fullstack" disclaimerBottomText="developer"/>
            <Typography>
              {description}
            </Typography>
    
            <LinksWrapper>
              <Button to="/work">Ultimos trabalhos :)</Button>
              <LinkAbout to="/about">mais sobre mim</LinkAbout>
            </LinksWrapper>
            <SocialLinks mobile/>
          </section >
    
          <AvatarWrapper>
            <Avatar />
            <AvatarLegend>
              Prazer em conhece-lo! <br/>Posso ajudar?
            </AvatarLegend>
          </AvatarWrapper>
        </Wrapper>
      </Layout>
    )
}

export default IndexPage
