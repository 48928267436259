import styled from 'styled-components'

export const Wrapper = styled.section`
    justify-content: space-between;
    display: flex;
    flex: inherit;

    @media(min-width: 2000px) {
        justify-content: space-evenly;
        align-items: center;
    }

    @media(max-width: 1000px) {
        justify-content: center;
    }
`