import styled from 'styled-components'

export const PageDisclaimerWrapper = styled.p`
    font-family: "Bungee"; 
    font-size: 5vw; 
    line-height: 6.5rem ;
    margin: 0 0 0 3rem;
    margin-left: ${({ page }) => page === "work" ? "5.5rem" : "0"};

    @media(max-width: 1000px) {
        font-size: 10vw; 
        line-height: 4.5rem ;
    }

    @media(max-width: 1000px) {
        font-size: 10vw; 
        line-height: 4.5rem ;
    }

    @media(max-width: 600px) {
        font-size: 12vw;
        line-height: 3.5rem;
    }

    @media(max-width: 400px) {
        font-size: 13vw;
        line-height: 3rem;
        margin: 0;
    }
`

export const PageDisclaimerTop = styled.span`
    margin-bottom: 15px;
`
