import styled from 'styled-components'

export const SocialLinksWrapper = styled.nav`    
    height: 75px;
    padding-top: 1rem;
    width: 22rem;
    border-top: none;

    display: flex;
    align-items: 0;
    justify-content: start;
    margin-left: 0;

    @media(max-width: 500px) {
        justify-content: center;
    }
`

export const SocialLinksList = styled.ul`
    align-items: center;
    display: flex;
    justify-content: space-around;
    list-style: none;
`

export const SocialLinksItem = styled.li`
    margin: 1.5rem;
`

export const SocialLinksLink = styled.a`
    color: #8899a6;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
        color: #1fa1f2;
    }
`

export const IconWrapper = styled.div`
    fill: #bbb;
    width: 25px;
    height: 25px;
`