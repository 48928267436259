import React from 'react'
import * as S from './styled'

const AvatarLegend = ({ children }) => (
    <S.AvatarLegendWrapper>
        <S.AvatarLegend>
            {children}
        </S.AvatarLegend>
    </S.AvatarLegendWrapper>
)

export default AvatarLegend