import { Github } from '@styled-icons/boxicons-logos/Github'
import { LinkedinSquare as Linkedin } from '@styled-icons/boxicons-logos/LinkedinSquare'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'

const Icons = {
    Github,
    Linkedin,
    Instagram
}

export default Icons