import React from 'react'
import * as S from './styled'
import TextStroke from '../TextStroke'

const PageDisclaimer = ({ styleTop, styleBottom, disclaimerTopText, disclaimerBottomText, styleWrapper, page }) => (
    <S.PageDisclaimerWrapper style={{ ...styleWrapper }} page={page}>
        <S.PageDisclaimerTop style={{ ...styleTop }}>{disclaimerTopText}</S.PageDisclaimerTop>
        <br />
        <TextStroke style={{ ...styleBottom }}>{disclaimerBottomText}</TextStroke>
    </S.PageDisclaimerWrapper>
)

export default PageDisclaimer