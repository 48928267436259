import React from 'react'

import * as S from './styled'

import Icons from './icons'
import links from './content'

const SocialLinks = ({ mobile }) => (
    <S.SocialLinksWrapper responsive={mobile}>
        <S.SocialLinksList>
            {links.map((link, index) => {
                const Icon = Icons[link.label]
                return (
                    <S.SocialLinksItem key={index}>
                        <S.SocialLinksLink href={link.url} title={link.label} target="_blank" rel="noopener noreferrer">
                            <S.IconWrapper>
                                <Icon color="black"/>
                            </S.IconWrapper>
                        </S.SocialLinksLink>
                    </S.SocialLinksItem>
                )
            })}
        </S.SocialLinksList>
    </S.SocialLinksWrapper>
)

export default SocialLinks