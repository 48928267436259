import styled from 'styled-components'
import Img from 'gatsby-image'

export const Image = styled(Img)`
    filter: grayscale(1); 
    border: 2px solid black;

    &:hover {
        filter: grayscale(0); 
    }

`