const links = [
    {
        label: 'Github',
        url: 'https://github.com/danilogila'
    },
    {
        label: 'Instagram',
        url: 'https://www.instagram.com/danilogila/'
    },
    {
        label: 'Linkedin',
        url: 'https://www.linkedin.com/in/danilo-gila-de-santana-306338106/'
    }
]

export default links